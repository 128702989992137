<template>
  <tr>
    <td>
      <div class="widget-content p-0">
        <div class="widget-content-wrapper">
          <div class="widget-content-left">
            <div class="widget-heading opacity-9 pl-1">
              {{ recibo.nombre.toUpperCase() }}
            </div>
            <div class="widget-subheading opacity-9">
              <span class="col-auto pl-1">
                Legajo:
                <b>
                  {{ recibo.numero_legajo }}
                </b>
              </span>
              <span class="col-auto pl-1">
                Cuil:
                <b>
                  {{ recibo.cuil | cuil }}
                </b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </td>
    <template v-if="tipo == 1">
      <td>
        <div class="widget-content p-0">
          <div
            class="
              widget-content-wrapper
              justify-content-center
              align-items-center
            "
          >
            <div class="widget-content-left">
              <div class="widget-heading">
                <span class="text-info"
                  >${{
                    recibo.haberes_remunerativos | numFormat("0,0.00")
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div class="widget-content p-0">
          <div
            class="
              widget-content-wrapper
              justify-content-center
              align-items-center
            "
          >
            <div class="widget-content-left">
              <div class="widget-heading">
                <span class="text-alternate"
                  >${{
                    recibo.haberes_no_remunerativos | numFormat("0,0.00")
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div class="widget-content p-0">
          <div
            class="
              widget-content-wrapper
              justify-content-center
              align-items-center
            "
          >
            <div class="widget-content-left">
              <div class="widget-heading">
                <span class="text-danger"
                  >${{
                    recibo.retenciones_descuentos | numFormat("0,0.00")
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </td>
    </template>
    <template v-if="tipo == 2">
      <td class="text-center">
        <div class="badge badge-warning" v-if="recibo.estado == 'pendiente'">
          Pendiente
        </div>
        <div v-if="recibo.estado == 'aceptado'">
          <div class="widget-heading opacity-9 pl-1">
            <div class="badge badge-success">Aceptado e impreso</div>
          </div>

          <span class="text-secondary"
            >Fecha Emisión: {{ recibo.fecha_firma | formatDate("d/m/Y") }}</span
          >
        </div>
      </td>
    </template>
    <td>
      <div class="widget-content p-0">
        <div
          class="
            widget-content-wrapper
            justify-content-center
            align-items-center
          "
        >
          <div class="widget-content-left">
            <div class="widget-heading">
              <span class="text-success"
                >${{ recibo.neto | numFormat("0,0.00") }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </td>
    <td>
      <div class="d-flex justify-content-around align-items-center">
        <button
          class="
            btn-icon btn-icon-right btn btn-outline-primary btn-sm
            align-items-center
          "
          title="Previsualizar"
          @click="verRecibo(recibo)"
        >
          <i class="fa fa-eye"></i>
        </button>
        <!-- <template v-if="tipo == 1">
          <button
            class="
              btn-icon btn-icon-right btn btn-outline-danger btn-sm
              align-items-center
            "
            title="No publicar"
            @click="noPublicar(recibo)"
            v-show="recibo.estado == 'pendiente'"
          >
            <i class="fa fa-ban"></i>
          </button>
          <button
            class="
              btn-icon btn-icon-right btn btn-outline-success btn-sm
              align-items-center
            "
            title="Publicar"
            @click="publicar(recibo)"
            v-show="recibo.estado == 'Sin publicar'"
          >
            <i class="fa fa-check"></i>
          </button>
        </template> -->
      </div>
    </td>
  </tr>
</template>
<script>
// import Recibo from "@/models/recibo";
export default {
  name: "RowRecibosAdmin",
  props: {
    tipo: {
      required: true,
      value: null,
    },
    recibo: {
      required: true,
      type: Object,
      value: {
        empleador: null,
        domicilio_empleador: null,
        cuit_empleador: null,
        cuil: null,
        nombre: null,
        antiguedad: null,
        afiliado: null,
        numero_legajo: null,
        seccion: null,
        cargo: null,
        categoria: null,
        domicilio: null,
        provincia: null,
        observaciones: null,
        obra_social_codigo: null,
        obra_social_nombre: null,
        per_dep: null,
        banco: null,
        haberes_remunerativos: null,
        haberes_no_remunerativos: null,
        retenciones_descuentos: null,
        neto: null,
        lugar_pago: null,
        estado: null,
        fecha_firma: null,
        created_at: null,
        updated_at: null,
        conceptos: null,
        liquidacion: null,
      },
    },
  },
  methods: {
    noPublicar(recibo) {
      console.log(recibo.estado);
      recibo.estado = "Sin publicar";
      console.log(recibo.estado);
    },
    publicar(recibo) {
      recibo.estado = "pendiente";
    },
    verRecibo(recibo){
      this.$emit('verRecibo', recibo);
    }
  },
};
</script>