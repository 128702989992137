<template>
  <div v-if="liquidacion">
    <div class="row">
      <div class="col-md-6 col-12 form-group input-group">
        <input
          type="text"
          class="form-control form-control-sm"
          placeholder="Buscar.."
          v-model="search"
          @keyup="searchQuery"
        />
        <span class="input-group-append">
          <button type="button" class="btn btn-sm btn-primary">
            <i class="fas fa-search"></i>
          </button>
        </span>
      </div>
    </div>

    <div class="table-responsive d-none d-md-block">
      <table
        class="
          align-middle
          mb-0
          table table-borderless table-striped table-hover
        "
      >
        <thead>
          <tr>
            <!-- <th class="text-center">#</th> -->
            <th>Liquidación</th>
            <template v-if="tipo == 1">
              <th class="text-center">Rem.</th>
              <th class="text-center">No Rem.</th>
              <th class="text-center">Ret./Desc.</th>
            </template>
            <template v-if="tipo == 2">
              <th class="text-center">Estado</th>
            </template>
            <th class="text-center">Neto</th>
            <th class="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <row-recibos-admin v-for="(recibo, i) in recibosFiltrados" :key="i" :recibo="recibo" :tipo="tipo" @verRecibo="verRecibo">
          </row-recibos-admin>
        </tbody>
      </table>
    </div>
    <ul class="list-group list-group-flush my-3 d-md-none d-block">
      <li
        class="list-group-item"
        v-for="recibo in recibosFiltrados"
        :key="recibo.id"
      >
        <div class="widget-content p-0">
          <div class="widget-content-wrapper align-items-start">
            <div class="widget-content-left">
              <div class="widget-subheading">
                {{ recibo.cuil | cuil }}
              </div>
              <div class="widget-heading">
                {{ recibo.nombre }}
              </div>
            </div>
            <div class="widget-content-right">
              <div
                class="badge badge-warning"
                v-if="recibo.estado == 'pendiente'"
              >
                Pendiente
              </div>
              <div
                class="badge badge-success"
                v-if="recibo.estado == 'aceptado'"
              >
                Emitido
              </div>
            </div>
          </div>
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <div class="widget-heading">
                <span class="text-secondary"> Legajo: </span
                >{{ recibo.numero_legajo }}
              </div>
            </div>
          </div>
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <div class="widget-heading">
                <span class="text-secondary">Total:</span>
                <span class="text-success"
                  >${{ recibo.neto | numFormat("0,0.00") }}</span
                >
              </div>
            </div>
          </div>
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <div class="widget-heading">
                <span class="text-secondary">Fecha Emisión:</span>
                {{ recibo.fecha_firma }}
              </div>
            </div>
          </div>
          <div class="widget-content-wrapper mt-1">
            <div class="w-100">
              <button
                class="
                  btn-icon btn-icon-right btn btn-outline-primary btn-sm
                  align-items-center
                "
                title="Previsualizar"
                @click="verRecibo(recibo)"
              >
                <i class="fa fa-eye"></i> Previsualizar
              </button>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <recibo-preview
      v-if="show"
      :recibo="reciboSelected"
      :readonly="true"
      @cerrar="cerrarModal"
    ></recibo-preview>
  </div>
</template>
<script>
import ReciboPreview from "@/views/recibos/ReciboPreview";
import RowRecibosAdmin from './RowRecibosAdmin.vue';
export default {
  name: "TablaRecibosAdmin",
  data() {
    return {
      search: "",
      recibosFiltrados: [],
      show: false,
      reciboSelected: null,
    };
  },
  components: {
    ReciboPreview,
    RowRecibosAdmin,
  },
  props: {
    liquidacion: { required: true, value: null },
    tipo: null,
  },
  computed:{
    bgEstado: (recibo) =>{
      if(recibo.estado == 'Sin publicar'){
        return 'bg-danger'
      }
      return ''
    }
  },
  mounted() {
    this.searchQuery();
  },
  methods: {
    searchQuery() {
      this.recibosFiltrados = this.liquidacion.recibos.filter((recibo) => {
        if (
          recibo.nombre.toUpperCase().includes(this.search.toUpperCase()) ||
          recibo.numero_legajo
            .toUpperCase()
            .includes(this.search.toUpperCase()) ||
          recibo.cuil.toUpperCase().includes(this.search.toUpperCase()) ||
          recibo.estado.toUpperCase().includes(this.search.toUpperCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
    },
    verRecibo(recibo) {
      this.reciboSelected = { ...recibo };

      const liquidacion = {
        cuit_institucion: this.liquidacion.cuit_institucion,
        fecha_pago: this.liquidacion.fecha_pago,
        periodo: this.liquidacion.periodo,
        tipo_liquidacion: this.liquidacion.tipo_liquidacion,
        institucion: this.liquidacion.institucion,
      };
      console.log(this.reciboSelected);
      this.reciboSelected.conceptos = this.reciboSelected.conceptos.filter(
        (concepto) => concepto.importe > 0
      );
      this.reciboSelected.liquidacion = liquidacion;
      console.log(this.reciboSelected);

      this.show = true;
    },
    cerrarModal() {
      this.reciboSelected = null;
      this.show = false;
    },
  },
};
</script>