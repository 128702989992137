<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h2 class="card-title">Seleccione el archivo que desea cargar</h2>
        <hr />
        <file-select class="offset-md-3 col-md-6" v-model="file"></file-select>
      </div>
    </div>
    <div class="card my-3">
      <div class="card-body">
        <h4 class="card-title">Previsualización</h4>
        <hr />
        <template v-if="cargado && liquidacion">
          <div class="widget-content p-2">
            <div class="widget-content-wrapper row">
              <div class="widget-content-left col-12">
                <h5 class="widget-heading text-primary pl-1">
                  {{ liquidacion.institucion.razon_social }}
                </h5>
                <div class="widget-subheading opacity-9">
                  <span class="col-auto pl-1">
                    Período:
                    <b>
                      {{ liquidacion.periodo }}
                    </b>
                  </span>
                  <span class="col-auto pl-1">
                    Tipo liquidación:
                    <b>
                      {{ liquidacion.tipo_liquidacion }}
                    </b>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <tabla-recibos-admin
            :tipo="1"
            :liquidacion="liquidacion"
          ></tabla-recibos-admin>
        </template>
      </div>
    </div>
    <div class="col-12 text-right mb-3">
      <button @click="guardarLiquidacion" class="btn btn-wide btn-primary">
        Guardar recibos
      </button>
    </div>
  </div>
</template>
<script>
import FileSelect from "@/components/layouts/FileSelect";
import Axios from "axios";
import Swal from "sweetalert2";
import pdf from "vue-pdf";
import TablaRecibosAdmin from "@/components/recibosAdmin/TablaRecibosAdmin.vue";

export default {
  name: "CargaMasiva",
  components: {
    FileSelect,
    pdf,
    TablaRecibosAdmin,
  },
  props: {
    titleCard: String,
    iconoTitulo: String,
  },
  data() {
    return {
      file: null,
      liquidacion: {
        institucion: null,
        recibos: null,
        cuil_institucion: null,
      },
      cargado: false,
    };
  },
  watch: {
    file: function (file) {
      if (file != null) {
        const reader = new FileReader();

        reader.onload = (e) => this.cargarLiquidacion(e.target.result);
        reader.readAsText(file);
      }
    },
  },
  methods: {
    cargarLiquidacion(value) {
      this.liquidacion = JSON.parse(value);
      this.liquidacion.recibos.sort(function (a, b) {
          if (a.nombre > b.nombre) {
            return 1;
          }
          if (a.nombre < b.nombre) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      for (let recibo of this.liquidacion.recibos) {
        // console.log(recibo)
        recibo.estado = "pendiente";
        if(!this.validarCampos(recibo)){
          this.liquidacion = null;
          break;
        }
      }
      this.getInstitucion(this.liquidacion.cuit_institucion);
    },
    getInstitucion(cuit) {
      Axios.get("/search/institucion/cuit/" + cuit)
        .then((r) => {
          if (r.data.institucion != null) {
            this.liquidacion.institucion = r.data.institucion;
            this.cargado = true;
          } else {
            Swal.fire(
              "Error",
              "El cuit de la institución no se corresponde con ninguna institución que usted administre",
              "Error"
            );
          }
        })
        .catch((e) => {
          console.log(e);
          Swal.fire("Error", "No se ha encontrado la institución", "Error");
        });
    },
    guardarLiquidacion() {
      Axios.post("recibos/carga-masiva", this.liquidacion)
        .then((res) => {
          if(res.data.success){
            Swal.fire("Éxito", "Se ha guardado correctamente", "success");
            this.$router.push({ name: "ListarLiquidaciones" });
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: `${res.data.error}`
            })
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `No se pudieron guardar los cambios. ${err}`,
          });
        });
    },
    validarCampos(recibo){
      if(recibo.cuit_empleador == '' || recibo.cuit_empleador == null){
        this.showError('Cuit de empleador')
        return false
      }
      if(recibo.cuil == '' || recibo.cuil == null){
        this.showError('CUIL')
        return false
      }
      if(recibo.nombre == '' || recibo.nombre == null){
        this.showError('Nombre')
        return false
      }
      if(recibo.antiguedad == '' || recibo.antiguedad == null){
        this.showError('Antigüedad')
        return false
      }
      if(recibo.afiliado != 0 && (recibo.afiliado == '' || recibo.afiliado == null)){
        this.showError('Afiliado')
        return false
      }
      if(recibo.numero_legajo == '' || recibo.numero_legajo == null){
        this.showError('Número de legajo')
        return false
      }
      if(recibo.seccion == '' || recibo.seccion == null){
        this.showError('Sección')
        return false
      }
      if(recibo.cargo == '' || recibo.cargo == null){
        this.showError('Cargo')
        return false
      }
      if(recibo.categoria == '' || recibo.categoria == null){
        this.showError('Categoría')
        return false
      }
      if(recibo.obra_social_nombre == '' || recibo.obra_social_nombre  == null){
        this.showError('Obra Social')
        return false
      }
      if(recibo.per_dep == '' || recibo.per_dep == null){
        this.showError('Período depositado')
        return false
      }
      if(recibo.banco == '' || recibo.banco == null){
        this.showError('Banco')
        return false
      }
      if(recibo.haberes_remunerativos != 0 && (recibo.haberes_remunerativos == '' || recibo.haberes_remunerativos == null)){
        this.showError('Haberes remunerativos')
        return false
      }
      if(recibo.haberes_no_remunerativos != 0 && (recibo.haberes_no_remunerativos == '' || recibo.haberes_no_remunerativos == null)){
        this.showError('Haberes no remunerativos')
        return false
      }
      if(recibo.retenciones_descuentos != 0 && (recibo.retenciones_descuentos == '' || recibo.retenciones_descuentos == null)){
        this.showError('Retenciones y descuentos')
        return false
      }
      if(recibo.neto != 0 && (recibo.neto == '' || recibo.neto == null)){
        this.showError('Neto')
        return false
      }
      if(recibo.lugar_pago == '' || recibo.lugar_pago == null){
        this.showError('Lugar de pago')
        return false
      }
      return true
    },
    showError(campo){
        Swal.fire({title: 'Error', icon: 'error', html: `<p>No se puede cargar la liquidación ya que se ha encontrado el campo <b>"${campo}"</b> vacío en un recibo.</p>`})
    }
  },
};
</script>